<template>
  <div class="box">
    <Header class="fixed-title" :title="type" :back="true"/>
    <div class="doc_news" v-if="name=='危疾家康保'">
      <ul class="protectdetailContain" v-if="type=='危疾'">
        <li class="rank4 rank">1、癌</li>
        <li class="rank4 rank">2、转移性脑肿瘤</li>
        <li class="rank4 rank">3、急性出血坏死性胰脏炎</li>
        <li class="rank4 rank">4、再生障碍性贫血</li>
        <li class="rank4 rank">5、复发性慢性胰腺炎</li>
        <li class="rank4 rank">6、芣期盰衮竭</li>
        <li class="rank4 rank">7、末期肺病</li>
        <li class="rank4 rank">8、暴发性肝炎</li>
        <li class="rank4 rank">9、肾衰竭</li>
        <li class="rank4 rank">10、主要器官移植</li>
        <li class="rank4 rank">11、囊肿性肾髓病</li>
        <li class="rank4 rank">12、严重克隆氏症</li>
        <li class="rank4 rank">13、伴有狼疮性肾炎的系统性红斑狼疮</li>
        <li class="rank4 rank">14、系统性硬皮病</li>
        <li class="rank4 rank">15、溃疡性结肠炎</li>
        <li class="rank4 rank">16、心肌疾病</li>
        <li class="rank4 rank">17、主动脉夹历オ</li>
        <li class="rank4 rank">18、艾森门格氏症状</li>
        <li class="rank4 rank">19、心脏病</li>
        <li class="rank4 rank">20、傅染性心内膜炎</li>
        <li class="rank4 rank">21、其他严重的冠状动脉疾病</li>
        <li class="rank4 rank">22、发性肺动脉高血压</li>
        <li class="rank4 rank">23、心瓣切换手术</li>
        <li class="rank4 rank">24、主动脉手术</li>
        <li class="rank4 rank">25、冠状动脉手术</li>
        <li class="rank4 rank">26、亚尔兹默氏病</li>
        <li class="rank4 rank">27、肌萎鏥性脊髓侧索硬化</li>
        <li class="rank4 rank">28、植物人</li>
        <li class="rank4 rank">29、细菌感染性脑膜炎</li>
        <li class="rank4 rank">30、良性脑肿瘤</li>
        <li class="rank4 rank">31、昏米</li>
        <li class="rank4 rank">32、克雅氏症</li>
        <li class="rank4 rank">33、失聪</li>
        <li class="rank4 rank">34、脑炎</li>
        <li class="rank4 rank">35、偏逻</li>
        <li class="rank4 rank">36、严重头部创伤</li>
        <li class="rank4 rank">37、结核脑膜炎</li>
        <li class="rank4 rank">38、运动神经元病症</li>
        <li class="rank4 rank">39、多发性硬化症</li>
        <li class="rank4 rank">40、肌肉营养不良症</li>
        <li class="rank4 rank">41、滩痪</li>
        <li class="rank4 rank">42、柏金逊病</li>
        <li class="rank4 rank">43、脊髓灰质炎（小儿麻痹症）</li>
        <li class="rank4 rank">44、延髓性逐渐鸡痪（PBP）</li>
        <li class="rank4 rank">45、进行性核上性麻痹</li>
        <li class="rank4 rank">46、严重重症肌无力</li>
        <li class="rank4 rank">47、中风</li>
        <li class="rank4 rank">48、完全失明</li>
        <li class="rank4 rank">49、逶遏输血而感染受滋病</li>
        <li class="rank4 rank">50、慢性肾上腺功能不全（即阿狄森氏病）</li>
        <li class="rank4 rank">51、糖尿病并发症</li>
        <li class="rank4 rank">52、伊波拉</li>
        <li class="rank4 rank">53、象皮病</li>
        <li class="rank4 rank">54、因被他人袭击引致感染人体免疫力缺乏病毒（HIV）</li>
        <li class="rank4 rank">55、断肢</li>
      </ul>
      <ul class="protectdetailContain" v-if="type=='早期危疾'">
        <li class="rank4 rank"> 1、原位癌（不包括皮唐原位癌，亦不包括原位黑色素瘤）*</li>
        <li class="rank4 rank"> 2、早期恶性肿瘤（早期前列腺癌、早期甲状腺乳头</li>
        <li class="rank4 rank"> 3、状瘤及AJCC第二期或以上的非黑色瘤皮唐癌）*</li>
        <li class="rank4 rank"> 4、非重型再生障碍性贫血</li>
        <li class="rank4 rank"> 5、肝脏手术</li>
        <li class="rank4 rank"> 6、肝炎侔肝硬化*</li>
        <li class="rank4 rank"> 7、愠怑自体孕疹肝炎*</li>
        <li class="rank4 rank"> 8、间质性纤维化的肺病</li>
        <li class="rank4 rank"> 9、移除单肺手术</li>
        <li class="rank4 rank"> 10、胯道再造术</li>
        <li class="rank4 rank"> 11、次级严重肾病</li>
        <li class="rank4 rank"> 12、主要器官移植（属于器官移植轮候名单上的轮候者）</li>
        <li class="rank4 rank"> 13、中度严重克隆氏症</li>
        <li class="rank4 rank"> 14、次级严重系统性红斑狼喑</li>
        <li class="rank4 rank"> 15、早期系统性硬皮病</li>
        <li class="rank4 rank"> 16、中度严重溃疡性结肠炎</li>
        <li class="rank4 rank"> 17、早期心肌病</li>
        <li class="rank4 rank">  18、腔静脉过懑鍪植入</li>
        <li class="rank4 rank"> 19、心包切除术</li>
        <li class="rank4 rank"> 20、心脏𣆥搏器或除颤器植入*</li>
        <li class="rank4 rank"> 21、次级严重傅染性心内膜炎</li>
        <li class="rank4 rank"> 22、周围动脉疾病的血管介入治疗*</li>
        <li class="rank4 rank"> 23、心瓣膜疾病的次级创伤性治疗</li>
        <li class="rank4 rank">  24、主动脉疾病或主动脉瘤的血管介入治疗*</li>
        <li class="rank4 rank">  25、微创性直接冠状动脉搭桥术*</li>
        <li class="rank4 rank">  26、血管成形术及其他冠状动脉之创伤性治疗法*</li>
        <li class="rank4 rank">  27、须作手术之脑动脉溜</li>
        <li class="rank4 rank"> 28、早期肌萎缩性侧索硬化症</li>
        <li class="rank4 rank">  29、脑硬膜下血肿手术</li>
        <li class="rank4 rank">  30、次级骰重细菌性腏朦炎</li>
        <li class="rank4 rank"> 31、脑垂体肿瘤切除手术</li>
        <li class="rank4 rank"> 32、次级严重昏迷</li>
        <li class="rank4 rank"> 33、耳蜗植入术</li>
        <li class="rank4 rank"> 34、单耳失聪</li>
        <li class="rank4 rank"> 35、次级严重脑炎</li>
        <li class="rank4 rank"> 36、中度严重脑部损伤</li>
        <li class="rank4 rank"> 37、脑分流器植入术</li>
        <li class="rank4 rank"> 38、次级严重结核性脑膜炎</li>
        <li class="rank4 rank"> 39、早期运动神经元病</li>
        <li class="rank4 rank"> 40、中度严重肌营养不良症</li>
        <li class="rank4 rank"> 41、中度严重瘫痪</li>
        <li class="rank4 rank"> 42、中度严重柏金逊病</li>
        <li class="rank4 rank"> 43、中度严重脊髓灰质炎</li>
        <li class="rank4 rank"> 44、早期进行性延髓麻痹症</li>
        <li class="rank4 rank"> 45、早期进行性核上神经麻痹症</li>
        <li class="rank4 rank"> 46、颈动脉𢦓形术及萁它颈动脉手术</li>
        <li class="rank4 rank"> 47、单目失明</li>
        <li class="rank4 rank"> 48、青光眼外科手术治疗</li>
        <li class="rank4 rank"> 49、严重精神病</li>
        <li class="rank4 rank"> 50、肾上腺腺瘤的肾上腺切除术</li>
        <li class="rank4 rank"> 51、糖尿病引致的肾脏病变*</li>
        <li class="rank4 rank"> 52、糖尿病引致的视网膜病变*</li>
        <li class="rank4 rank"> 53、妊娠糖尿病*</li>
        <li class="rank4 rank"> 54、失去单肢</li>
      </ul>
    </div>
    <div class="doc_news" v-if="name=='爱伴航'">
      <ul class="protectdetailContain" v-if="type=='58种危疾'">
        <li class="rank2 rank" style="text-align:center;margin-bottom:20px">（包括57种严重疾病及1种非严重疾病）</li>
        <li class="rank3 rank">第1类 癌症</li>
        <li class="rank4 rank">1. 癌</li>
        <li class="rank3 rank">第2类 与心脏相关之疾病</li>
        <li class="rank4 rank">2. 心肌病</li>
        <li class="rank4 rank">3. 冠状动脉手术</li>
        <li class="rank4 rank">4. 心磁病</li>
        <li class="rank4 rank">5. 心瓣蛋换及修补</li>
        <li class="rank4 rank">6. 传染性心内膜炎</li>
        <li class="rank4 rank">7. 其他严重的冠状动脉疾病</li>
        <li class="rank4 rank">8. 肺动脉高血压(原发性)</li>
        <li class="rank4 rank">9. 主动脉手术</li>
        <li class="rank3 rank">第3类 与神经系统相关之疾病</li>
        <li class="rank4 rank">10. 亚尔兹默氏病/不可还原之器质性脑退化</li>
        <li class="rank4 rank">11. 植物人</li>
        <li class="rank4 rank">12. 细菌性脑(脊)膜炎</li>
        <li class="rank4 rank">13. 良性脑肿瘤</li>
        <li class="rank4 rank">14. 须作手术之脑动脉瘤显</li>
        <li class="rank4 rank">15. 昏迷</li>
        <li class="rank4 rank">16. 脑炎</li>
        <li class="rank4 rank">17. 偏瘫</li>
        <li class="rank4 rank">18. 严重头部创伤</li>
        <li class="rank4 rank">19. 脑膜结核病</li>
        <li class="rank4 rank">20. 运动神经原疾病（包括脊髓性肌肉荾缩症、渐进延髓麻痹、肌萎缩性侧泰硬化症及原发性侧索硬化症）</li>
        <li class="rank4 rank">21. 多发性硬化症</li>
        <li class="rank4 rank">22. 肌营养不良症</li>
        <li class="rank4 rank">23. 瘫痰</li>
        <li class="rank4 rank">24. 柏金逊症</li>
        <li class="rank4 rank">25. 脊镇灭质炎</li>
        <li class="rank4 rank">26. 进行性核上神经麻痹症</li>
        <li class="rank4 rank">27. 严重重症肌无力</li>
        <li class="rank4 rank">28. 中风</li>
        <li class="rank3 rank">第4类 与主要器官和功能有关之疾病</li>
        <li class="rank4 rank">29. 急性坏死及出血性胰腺炎</li>
        <li class="rank4 rank">30. 再生障碍性贫血</li>
        <li class="rank4 rank">31. 慢性肝病</li>
        <li class="rank4 rank">32. 复发性慢性胰脏炎</li>
        <li class="rank4 rank">33. 末期肺病</li>
        <li class="rank4 rank">34. 暴发性病毒性肝炎</li>
        <li class="rank4 rank">35. 肾衰竭</li>
        <li class="rank4 rank">36. 主要器官移植</li>
        <li class="rank4 rank">37. 肾髓质囊肿病</li>
        <li class="rank4 rank">38. 系统性红斑狼疮连狼疮性肾炎</li>
        <li class="rank4 rank">39. 系统性硬皮病</li>
        <li class="rank3 rank">第5类 其他严重疾病</li>
        <li class="rank4 rank">40. 因输血而感染爱滋病</li>
        <li class="rank4 rank">41. 失明</li>
        <li class="rank4 rank">42. 慢性肾上腺功能不全(即阿狄森氏病)</li>
        <li class="rank4 rank">43. 库贾氏病</li>
        <li class="rank4 rank">44. 克罗恩氏病</li>
        <li class="rank4 rank">45. 伊波拉</li>
        <li class="rank4 rank">46. 象皮病</li>
        <li class="rank4 rank">47. 失聪</li>
        <li class="rank4 rank">48. 失去一肢及一眼</li>
        <li class="rank4 rank">49. 丧失语言能力</li>
        <li class="rank4 rank">50. 失去两肢</li>
        <li class="rank4 rank">51. 严重烧伤</li>
        <li class="rank4 rank">52. 坏死性筋膜炎(俗称食肉菌感染)</li>
        <li class="rank4 rank">53. 因职业感染人体免疫力缺乏病毒(HIV)</li>
        <li class="rank4 rank">54. 嗜铬细胞瘤</li>
        <li class="rank4 rank">55. 严重类风湿性关节炎</li>
        <li class="rank4 rank">56. 严重溃疡性结肠炎</li>
        <li class="rank3 rank">第6类 末期疾病及不能独立生活</li>
        <li class="rank4 rank">57. 不能独立生活</li>
        <li class="rank4 rank">58. 末期疾病</li>
      </ul>
      <ul class="protectdetailContain" v-if="type=='44种早期危疾'">
        <li class="rank3 rank">第1类 癌症</li>
        <li class="rank4 rank">1. 癌</li>
        <li class="rank4 rank">2. 早期恶性肿瘤</li>
        <li class="rank3 rank">第2类 与心脏相关之疾病</li>
        <li class="rank4 rank">3.主动脉疾病的血管介入治疗或主动脉瘤</li>
        <li class="rank4 rank">4.心瓣膜疾病的次级创伤性治疗</li>
        <li class="rank4 rank">5.次级严重心脏疾病（包括植入心脏起搏器或除纤颤器）</li>
        <li class="rank4 rank">6.次级严重传染性心内膜炎</li>
        <li class="rank4 rank">7.微创进行直接的冠状动脉搭桥手术</li>
        <li class="rank4 rank">8.经皮穿刺冠状动脉介人（俗称「通波仔」）</li>
        <li class="rank4 rank">9.心包切除手术</li>
        <li class="rank3 rank">第3类 与神经系统相关之疾病</li>
        <li class="rank4 rank">10. 于颈动脉进行血管成形术或内膜切除术</li>
        <li class="rank4 rank">11. 植入大脑内分流器</li>
        <li class="rank4 rank">12. 早期脑退化症（包括早期亚尔兹默氏症）</li>
        <li class="rank4 rank">13. 大脑动脉瘤的血管介人治疗</li>
        <li class="rank4 rank">14. 次级严重细茵性脑（脊）膜炎</li>
        <li class="rank4 rank">15. 次级严重昏迷</li>
        <li class="rank4 rank">16. 次级严重脑炎</li>
        <li class="rank4 rank">17. 次级严重柏金逊症</li>
        <li class="rank4 rank">18. 中度严重脑部损伤</li>
        <li class="rank4 rank">19. 中度严重瘫痪</li>
        <li class="rank4 rank">20. 严重精神病</li>
        <li class="rank4 rank">21. 脑硬膜下血肿手术</li>
        <li class="rank4 rank">22. 脑下垂体肿瘤切除手术</li>
        <li class="rank3 rank">第4类 与主要器官和功能有关之疾病</li>
        <li class="rank4 rank">23.胆道重建手术</li>
        <li class="rank4 rank">24.慢性肺病</li>
        <li class="rank4 rank">25.肝炎连肝硬化</li>
        <li class="rank4 rank">26.次级严重再生障碍性贫血</li>
        <li class="rank4 rank">27.次级严重肾脏疾病</li>
        <li class="rank4 rank">28.次级严重系统性红斑狼疮</li>
        <li class="rank4 rank">29.肝脏手术</li>
        <li class="rank4 rank">30.主要器官移植（于器官移植轮候册名单上）</li>
        <li class="rank4 rank">31.单肺切除手术</li>
        <li class="rank3 rank">第5类 其他严重疾病</li>
        <li class="rank4 rank">32.植入人工耳蜗手术</li>
        <li class="rank4 rank">33.糖尿病视网膜病变</li>
        <li class="rank4 rank">34.周围动脉疾病的血管介人治疗</li>
        <li class="rank4 rank">35.意外引致的脸部烧伤</li>
        <li class="rank4 rank">36.意外受伤所需的面容重建手术</li>
        <li class="rank4 rank">37.意外引致的次级严重身体烧伤</li>
        <li class="rank4 rank">38.次级严重库贾氏病</li>
        <li class="rank4 rank">39.单耳失聪</li>
        <li class="rank4 rank">40.失去一肢</li>
        <li class="rank4 rank">41.单眼失明</li>
        <li class="rank4 rank">42.骨质疏松症连骨折</li>
        <li class="rank4 rank">43.严重中枢神经性睡眠窒息症或混合性睡眠窒息症</li>
        <li class="rank4 rank">44.严重阻塞性睡眠窒息症</li>        
      </ul>
      <ul class="protectdetailContain" v-if="type=='首10年升级保障'">
        <li class="rank11 rank">首10年升级保障：</li>
        <li class="rank11 rank"><i class="el-icon-info"></i> 在首10个保单年度，我们会提供升级保障，即适用于58种危疾（包括57种严重疾病及1种非严重疾病）、身故保障或级别二深切治疗保障赔偿的一笔过额外35%原有保额的赔偿。此升级保障不会被任何早期危疾及级别一深切治疗保障赔偿的预支赔偿减少。</li>
        <li class="rank11 rank"><i class="el-icon-info"></i> 根据保单审批时的核保决定，我们可为您提供升级保障的转换权。您可选择在升级保障生效期的最后1个保单年度或紧随着受保人64岁生日后之保障周年日起（以较先者为准），把升级保障的结余转换为终身寿险或危疾保障终身寿险计划（须额外缴付保费），而毋须再次提供健康申报。一经转换后，升级保障将被退保，而新保单将于退保日起生效。</li>    
      </ul>
    </div>
    <div class="doc_news" v-if="name=='富通守护168加强版'">
      <ul class="protectdetailContain" v-if="type=='严重程度3'">
        <li class="rank2 rank"> 组别一：癌症</li>
        <li class="rank3 rank"> 1癌症</li>
        <li class="rank2 rank"> 组别二：与肺和功能有关的疾病</li>
        <li class="rank3 rank"> 2慢性阻塞性肺病</li>
        <li class="rank3 rank"> 3末期肺病</li>
        <li class="rank3 rank"> 4严重支气管扩张</li>
        <li class="rank3 rank"> 5严重肺气肿</li>
        <li class="rank3 rank"> 6严重肺纤维化</li>
        <li class="rank2 rank"> 组别三：与主要器官和功能有关的疾病</li>
        <li class="rank3 rank">7慢性自体免疫性肝炎</li>
        <li class="rank3 rank">8再发性慢性胰脏炎</li>
        <li class="rank3 rank">9末期肾衰竭</li>
        <li class="rank3 rank">10末期肝衰竭</li>
        <li class="rank3 rank">11暴发性病毒性肝炎</li>
        <li class="rank3 rank">12主要器官移植</li>
        <li class="rank3 rank">13囊肿性肾髓病</li>
        <li class="rank3 rank">14严重克隆氏病</li>
        <li class="rank3 rank">15严重类风湿关节炎</li>
        <li class="rank3 rank">16严重溃疡性结肠炎</li>
        <li class="rank3 rank">17系统性红斑狼疮合并狼疮肾炎</li>
        <li class="rank3 rank">18系统性硬皮病</li>
        <li class="rank2 rank"> 组别四：与心脏有关的疾病</li>
        <li class="rank3 rank">19冠状动脉绕道手术</li>
        <li class="rank3 rank">20艾森门格综合症</li>
        <li class="rank3 rank">21心脏瓣膜替换</li>
        <li class="rank3 rank">22感染性心内膜炎</li>
        <li class="rank3 rank">23其他严重的冠状动脉疾病</li>
        <li class="rank3 rank">24原发性肺动脉高压</li>
        <li class="rank3 rank">25严重心肌病</li>
        <li class="rank3 rank">26严重心脏病发作</li>
        <li class="rank3 rank">27主动脉手术</li>
        <li class="rank2 rank">组别五：与神经系统相关的疾病</li>
        <li class="rank3 rank">28年老痴呆</li>
        <li class="rank3 rank">29肌萎缩性脊髓侧索硬化</li>
        <li class="rank3 rank">30植物人</li>
        <li class="rank3 rank">31良性脑肿瘤</li>
        <li class="rank3 rank">32克雅二氏病（疯牛病）</li>
        <li class="rank3 rank">33偏瘫</li>
        <li class="rank3 rank">34多发性硬化症</li>
        <li class="rank3 rank">35瘫痪（两肢或以上）</li>
        <li class="rank3 rank">36脊髓灰质炎</li>
        <li class="rank3 rank">37原发性侧索硬化症</li>
        <li class="rank3 rank">38进行性核上神经淋痹症</li>
        <li class="rank3 rank">39严重细菌性脑膜炎</li>
        <li class="rank3 rank">40严重昏迷</li>
        <li class="rank3 rank">41严重脑炎</li>
        <li class="rank3 rank">42严重的头部创伤</li>
        <li class="rank3 rank">43严重肌肉营养不良症</li>
        <li class="rank3 rank">44严重重症肌无力症</li>
        <li class="rank3 rank">45严重帕金逊症</li>
        <li class="rank3 rank">46严重延髓性逐渐瘫痪</li>
        <li class="rank3 rank">47严重进行性肌肉萎缩症</li>
        <li class="rank3 rank">48脊髓肌肉萎缩症</li>
        <li class="rank3 rank">49中风</li>
        <li class="rank3 rank">50结核性脑膜炎</li>
        <li class="rank2 rank">组别六：其他危疾</li>
        <li class="rank3 rank">51糖尿病并发症引致切除双足</li>
        <li class="rank3 rank">52再生障碍性贫血</li>
        <li class="rank3 rank">53慢性肾上腺功能不足</li>
        <li class="rank3 rank">54伊波拉病毒</li>
        <li class="rank3 rank">55象皮病</li>
        <li class="rank3 rank">56因输血感染人类免疫力缺乏病毒</li>
        <li class="rank3 rank">57不能独立生活（保障至64岁）</li>
        <li class="rank3 rank">58丧失肢体（一肢）及单目失明</li>
        <li class="rank3 rank">59丧失说话能力</li>
        <li class="rank3 rank">60丧失肢体（两肢或以上）</li>
        <li class="rank3 rank">61医疗引致感染人类免疫力缺乏病毒</li>
        <li class="rank3 rank">62坏死性筋膜炎</li>
        <li class="rank3 rank">63因职业感染人类免疫力缺乏病毒</li>
        <li class="rank3 rank">64嗜铬细胞瘤</li>
        <li class="rank3 rank">65严重烧伤</li>
        <li class="rank3 rank">66末期疾病</li>
        <li class="rank3 rank">67完全及永久伤残（保障至75岁）</li>
        <li class="rank3 rank">68失明</li>
        <li class="rank3 rank">69失聪</li>
      </ul>
      <ul class="protectdetailContain" v-if="type=='严重程度2'">
        <li class="rank2 rank">组别一：癌症</li>
        <li class="rank3 rank">1乳房原位癌的全乳房切除手术治疗</li>
        <li class="rank3 rank">2次级严重前列腺恶性肿瘤的全前列腺切除手术治疗</li>
        <li class="rank2 rank">组别二：与肺和功能有关的疾病</li>
        <li class="rank3 rank">3单肺切除手术</li>
        <li class="rank2 rank">组别三：与主要器官和功能有关的疾病</li>
        <li class="rank3 rank">4慢性肾功能损害</li>
        <li class="rank3 rank">5肝炎引致肝硬化</li>
        <li class="rank2 rank">组别四：与心脏有关的疾病</li>
        <li class="rank3 rank">6心脏瓣膜替换（连永久装置或取代假体）</li>
        <li class="rank3 rank">7微创冠状动脉搭桥手术</li>
        <li class="rank3 rank">8主动脉瘤支架置入术</li>
        <li class="rank2 rank">组别五：与神经系统相关的疾病</li>
        <li class="rank3 rank">9脑动脉瘤的开颅手术治疗</li>
        <li class="rank3 rank">10昏迷72小时</li>
        <li class="rank3 rank">11瘫痪（一肢）</li>
        <li class="rank2 rank">组别六：其他危疾</li>
        <li class="rank3 rank">12因侵害而感染人类免疫力缺乏病毒</li>
        <li class="rank3 rank">13因器官移植而感染人类免疫力缺乏病毒</li>
        <li class="rank3 rank">14丧失肢体（一肢）</li>
        <li class="rank3 rank">15视神经萎缩</li>
      </ul>
      <ul class="protectdetailContain" v-if="type=='严重程度1'">
        <li class="rank2 rank">组别一：癌症</li>
        <li class="rank3 rank">1原位癌</li>
        <li class="rank3 rank">2次级严重癌症</li>
        <li class="rank2 rank">组别二：与肺和功能有关的疾病</li>
        <li class="rank3 rank">3慢性肺病</li>
        <li class="rank3 rank">4粟粒性肺结核</li>
        <li class="rank2 rank">组别三：与主要器官和功能有关的疾病</li>
        <li class="rank3 rank">5胆道系统重建手术</li>
        <li class="rank3 rank">6肠系膜动脉疾病之血管介入治疗</li>
        <li class="rank3 rank">7肾动脉疾病之血管介入治疗</li>
        <li class="rank3 rank">8次级严重克隆氏病</li>
        <li class="rank3 rank">9次级严重肾衰竭</li>
        <li class="rank3 rank">10次级严重类风湿关节炎</li>
        <li class="rank3 rank">11次级严重硬皮病</li>
        <li class="rank3 rank">12次级严重系统性红斑狼疮</li>
        <li class="rank3 rank">13次级严重溃疡性结肠炎</li>
        <li class="rank3 rank">14主要器官移植</li>
        <li class="rank3 rank">15局部肝脏手术</li>
        <li class="rank3 rank">16植入静脉过滤器</li>
        <li class="rank3 rank">17单肾切除手术</li>
        <li class="rank3 rank">18气管造口术（于器官移植轮候册名单上）</li>
        <li class="rank2 rank">组别四：与心脏有关的疾病</li>
        <li class="rank3 rank">19冠状血管成形术备注5</li>
        <li class="rank3 rank"> 20颈动脉手术</li>
        <li class="rank3 rank">21经血管内心脏瓣膜介入</li>
        <li class="rank3 rank">22主动脉疾病的血管介入治疗或主动脉瘤</li>
        <li class="rank3 rank">23次级严重心肌病</li>
        <li class="rank3 rank">24次级严重心脏病发作备注5</li>
        <li class="rank3 rank">25次级严重感染性心内膜炎</li>
        <li class="rank3 rank">26心包切除术</li>
        <li class="rank3 rank"> 27永久性植入心脏除纤颤器备注5</li>
        <li class="rank3 rank">28永久性植入心脏起搏器备注5</li>
        <li class="rank3 rank">29继发性肺动脉高压</li>
        <li class="rank2 rank">组别五：与神经系统相关的疾病</li>
        <li class="rank3 rank">30植入大脑内分流器</li>
        <li class="rank3 rank">31早期脑退化症（包括早期阿滋海默症）</li>
        <li class="rank3 rank">32脑动脉瘤的血管内介入手术治疗</li>
        <li class="rank3 rank">33次级严重肌萎缩性脊髓侧索硬化症</li>
        <li class="rank3 rank"> 34次级严重细菌性脑膜炎</li>
        <li class="rank3 rank">35次级严重昏迷</li>
        <li class="rank3 rank">36次级严重克雅二氏病（疯牛病）</li>
        <li class="rank3 rank">37次级严重脑炎</li>
        <li class="rank3 rank">38次级严重头部创伤</li>
        <li class="rank3 rank">39次级严重多发性硬化症</li>
        <li class="rank3 rank">40次级严重肌肉营养不良症</li>
        <li class="rank3 rank">41次级严重重症肌无力症</li>
        <li class="rank3 rank">42次级严重帕金逊症</li>
        <li class="rank3 rank">43次级严重脊髓灰质炎</li>
        <li class="rank3 rank">44次级严重原发性侧索硬化症</li>
        <li class="rank3 rank">45次级严重延髓性逐渐瘫痪</li>
        <li class="rank3 rank">46次级严重进行性肌肉萎缩</li>
        <li class="rank3 rank"> 47次级严重进行性核上神经淋痹症</li>
        <li class="rank3 rank"> 48次级严重脊髓肌肉萎缩症</li>
        <li class="rank3 rank"> 49垂体瘤</li>
        <li class="rank3 rank">50脑硬膜下血肿手术</li>
        <li class="rank3 rank"> 51结核性脊髓炎</li>
        <li class="rank2 rank">组别六：其他危疾</li>
        <li class="rank3 rank">52急性再生障碍性贫血</li>
        <li class="rank3 rank">53急性坏死及出血性胰腺炎</li>
        <li class="rank3 rank">54因肾上腺腺瘤切除肾上腺 （保障至70岁）</li>
        <li class="rank3 rank">55糖尿病并发症引致切除单足 （保障由2岁起）</li>
        <li class="rank3 rank"> 56植入人工耳蜗手术备注5</li>
        <li class="rank3 rank">57糖尿病视网膜病变</li>
        <li class="rank3 rank">58意外引致的脸部烧伤</li>
        <li class="rank3 rank">59意外受伤所需的面容重建手术</li>
        <li class="rank3 rank">60溶血性链球菌引致之坏疽</li>
        <li class="rank3 rank">61次级严重烧伤</li>
        <li class="rank3 rank">62次级严重象皮病</li>
        <li class="rank3 rank">63主要器官功能损害+</li>
        <li class="rank3 rank">64单目失明</li>
        <li class="rank3 rank">65因声带麻痹导致丧失说话能力</li>
        <li class="rank3 rank">66骨质疏松症连骨折</li>
        <li class="rank3 rank">67严重听力受损备注5</li>
        <li class="rank3 rank">68皮肤移植</li>
      </ul>
    </div>
    <div class="doc_news" v-if="name=='活耀人生危疾保2'">
      <ul class="protectdetailContain" v-if="type=='严重危疾'">
        <li class="rank3 rank">1.癌症</li>
        <li class="rank3 rank">2.急性坏死性胰脏炎</li>
        <li class="rank3 rank">3.因输血而感染爱滋病</li>
        <li class="rank3 rank">4.亚尔兹默氏症/不可还原之器质脑退化性疾病（痴呆）</li>
        <li class="rank3 rank">5.肌萎缩性脊髓侧索硬化</li>
        <li class="rank3 rank">6.植物人</li>
        <li class="rank3 rank">7.再生障碍性贫血</li>
        <li class="rank3 rank">8.细菌性脑（脊）膜炎</li>
        <li class="rank3 rank">9.良性脑肿瘤</li>
        <li class="rank3 rank"> 10.双目失明</li>
        <li class="rank3 rank"> 11.心肌病</li>
        <li class="rank3 rank"> 12.慢性肾上腺功能不全</li>
        <li class="rank3 rank"> 13.再发性慢性胰脏炎</li>
        <li class="rank3 rank">14.昏迷</li>
        <li class="rank3 rank">15.冠状动脉搭桥手术</li>
        <li class="rank3 rank"> 16.克雅二氏症</li>
        <li class="rank3 rank"> 17.伊波拉出血热</li>
        <li class="rank3 rank"> 18.象皮病</li>
        <li class="rank3 rank"> 19.脑炎</li>
        <li class="rank3 rank"> 20.末期肝病</li>
        <li class="rank3 rank"> 21.末期肺病</li>
        <li class="rank3 rank"> 22.暴发性病毒性肝炎</li>
        <li class="rank3 rank"> 23.溶血性链球菌引致之坏疽</li>
        <li class="rank3 rank"> 24.突发性心脏病（心肌梗塞）</li>
        <li class="rank3 rank"> 25.心办手术</li>
        <li class="rank3 rank"> 26.因侵害而感染之人类免疫力缺乏症病毒</li>
        <li class="rank3 rank"> 27.感染性心内膜炎</li>
        <li class="rank3 rank"> 28.肾衰竭</li>
        <li class="rank3 rank"> 29.失聪</li>
        <li class="rank3 rank"> 30.断肢</li>
        <li class="rank3 rank"> 31.失去一肢及一眼</li>
        <li class="rank3 rank"> 32.丧失语言能力</li>
        <li class="rank3 rank"> 33.严重灼伤</li>
        <li class="rank3 rank"> 34.严重头部创伤</li>
        <li class="rank3 rank"> 35.主要器官移植</li>
        <li class="rank3 rank"> 36.囊肿性肾髓病</li>
        <li class="rank3 rank"> 37.多发性硬化</li>
        <li class="rank3 rank"> 38.遗传性肌肉萎缩症</li>
        <li class="rank3 rank">39.重症肌无力</li>
        <li class="rank3 rank">40.因职业引致之人类免疫力缺乏症病毒</li>
        <li class="rank3 rank"> 41.其他严重冠状动脉疾病</li>
        <li class="rank3 rank"> 42.瘫痪</li>
        <li class="rank3 rank"> 43.柏金逊病</li>
        <li class="rank3 rank"> 44.嗜铬细胞瘤</li>
        <li class="rank3 rank"> 45.脊髓灰质炎（小儿麻痹症）</li>
        <li class="rank3 rank"> 46.原发性侧索硬化</li>
        <li class="rank3 rank"> 47.原发性肺动脉高血压</li>
        <li class="rank3 rank"> 48.延髓性逐渐瘫痪</li>
        <li class="rank3 rank"> 49.进行性肌肉萎缩</li>
        <li class="rank3 rank"> 50.核上神经逐渐瘫痪</li>
        <li class="rank3 rank">51.严重克罗恩氏病</li>
        <li class="rank3 rank"> 52.严重类风湿关节炎</li>
        <li class="rank3 rank"> 53.严重溃疡性结肠炎</li>
        <li class="rank3 rank"> 54.脊骨肌萎缩症</li>
        <li class="rank3 rank"> 55.中风</li>
        <li class="rank3 rank"> 56.主动脉手术</li>
        <li class="rank3 rank"> 57.红斑狼疮</li>
        <li class="rank3 rank"> 58.系统性硬化</li>
        <li class="rank3 rank">59.末期疾病</li>
        <li class="rank3 rank"> 60.完全及永久伤残*</li>
      </ul>
      <ul class="protectdetailContain" v-if="type=='早期危疾'">
        <li class="rank3 rank">1.因肾上腺腺瘤的肾上腺切除术</li>
        <li class="rank3 rank">2.因冠状动脉疾病进行血管成形术及其他创伤性治疗</li>
        <li class="rank3 rank">3.于颈动脉进行血管成形术及植入支架</li>
        <li class="rank3 rank">4.胆道重建手术</li>
        <li class="rank3 rank">5.原位癌</li>
        <li class="rank3 rank">6.心脏起搏器植入术</li>
        <li class="rank3 rank">7.颈动脉手术</li>
        <li class="rank3 rank"> 8.须作手术之大脑动脉瘤动静脉𠵇𢒋</li>
        <li class="rank3 rank">9.植入大脑内分流器</li>
        <li class="rank3 rank">10.慢性肺病</li>
        <li class="rank3 rank">11.植入人工耳蜗手术</li>
        <li class="rank3 rank">12.糖尿病视网膜病变</li>
        <li class="rank3 rank">13.早期恶性肿瘤</li>
        <li class="rank3 rank">14.早期甲状腺癌</li>
        <li class="rank3 rank">15.大脑动脉瘤的血管介入治疗</li>
        <li class="rank3 rank">16.周围动脉疾病的血管介入治疗</li>
        <li class="rank3 rank">17.主动脉疾病的血管介入治疗或主动脉瘤</li>
        <li class="rank3 rank">18.意外引致的脸部灼伤</li>
        <li class="rank3 rank">19.意外受伤所需的面容重建手术</li>
        <li class="rank3 rank">20.肝炎连肝硬化</li>
        <li class="rank3 rank">21.植入静脉过滤器</li>
        <li class="rank3 rank">22.心办膜疾病的次级创伤性治疗</li>
        <li class="rank3 rank">23.次级严重细菌性脑（脊）膜炎</li>
        <li class="rank3 rank"> 24.意外引致的级严重身体灼伤</li>
        <li class="rank3 rank"> 25.次级严重昏迷</li>
        <li class="rank3 rank">26.次级严重脑炎</li>
        <li class="rank3 rank">27.次级严重心臟疾病</li>
        <li class="rank3 rank">28.次级严重肾脏疾病</li>
        <li class="rank3 rank"> 29.次级严重系统性红斑狼疮</li>
        <li class="rank3 rank">30.肝臟手术</li>
        <li class="rank3 rank">31.单耳失聪</li>
        <li class="rank3 rank">32.失去一肢</li>
        <li class="rank3 rank">33.单眼失明</li>
        <li class="rank3 rank">34.主要器官移植（于器官移植轮候册名单上）</li>
        <li class="rank3 rank"> 35.粟粒性肺结核</li>
        <li class="rank3 rank">36.中度严重瘫痪</li>
        <li class="rank3 rank"> 37.脊髓炎</li>
        <li class="rank3 rank">38.骨质疏松症连骨折</li>
        <li class="rank3 rank"> 39.心包切除术</li>
        <li class="rank3 rank"> 40.皮肤移植</li>
        <li class="rank3 rank"> 41.脑硬膜下血肿手术</li>
        <li class="rank3 rank">42.单肾切除手术</li>
        <li class="rank3 rank"> 43.单肺切除手术</li>
        <li class="rank3 rank">44.脑下垂体肿瘤切除手术</li>
      </ul>
      <ul class="protectdetailContain" v-if="type=='儿童疾病'">
       <li class="rank3 rank"> 1.一型糖尿病</li>
       <li class="rank3 rank">2.川崎病</li>
       <li class="rank3 rank">3.风湿热合并心瓣膜损害</li>
       <li class="rank3 rank"> 4.斯蒂尔病</li>
       <li class="rank3 rank"> 5.严重哮喘</li>
       <li class="rank3 rank"> 6.出血性登革热</li>
       <li class="rank3 rank"> 7.自闭症</li>
       <li class="rank3 rank"> 8.威尔逊病</li>
      </ul>
    </div>
    <div class="doc_news" v-if="name=='万年青-传承'">
      <ul class="protectdetailContain" v-if="type=='投保说明'">
        <li class="rank11 rank"> 1.上列为您的基本计划主要利益之说明摘要，如欲知悉更多资料，或（如适当）索取更详尽的建议书，请与您的持牌保险中介人或本公司联络。</li>
        <li class="rank11 rank">2.以上只显示您的基本计划的利益说明，并未将「保障摘要」所列出之附加保障（如适用）计算在内。</li>
        <li class="rank11 rank">3.以上数据是基于下列假设而定：（1）现行的归原红利及终期红利分配比例一直维持不变；（2）保单没有任何保单贷款/预支金额；（3）保单并无提取任何款项；（4）您会全数支付应缴保费。</li>
        <li class="rank11 rank"> 4.以上非保证金额已考虑保单下可支付的归原红利及终期红利（统称为「红利」）。归原红利在公布后将于本保单内累积，而在日后公布终期红利时，终期红利或会有所增减。已公布的红利之面值为其中一项厘定身故保障之因素。惟当保单退保（全部或部份）或保单终止（非因受保人身故而引致）时，本公司仅支付红利的现金价值。红利乃根据本公司现时预计之红利分配推算，而并非保证的，并可由本公司全权厘定。实际获发之金额或比上述所显示者较高或较低。而本公司的红利分配推算基本上根据数个经验因素之表现而改变，其中投资回报（包含资产拖欠及投资开支所带来的影响）通常被视为该等红利表现的主要决定因素。其他因素包括（但不限于）索偿经验、保单开支、税项及保单持有人的终止保单经验。</li>
        <li class="rank11 rank"> 5.以上显示的退保价值总额是保证现金价值、非保证累积归原红利之现金价值（如有）及终期红利之现金价值（如有）之总和，加上存放于本公司之任何款项，并扣除任何贷款与利息的金额。</li>
        <li class="rank11 rank"> 6.以上显示的身故保障总额相等于（i）及（ii）之较高者，（i）基本计划之到期及已缴保费总额减任何已提取之累积归原红利现金价值；及（ii）就受保人身故当日的保证现金价值及任何累积归原红利和终期红利的面值之总和；加上存放于本公司之任何款项，并扣除任何贷款与利息的金额。</li>
        <li class="rank11 rank"> 7.您可提取全部或部份累积归原红利之现金价值（如有），随后的累积归原红利之现金价值及面值将相应减少。提取归原红利将减低保单的长期价值。</li>
        <li class="rank11 rank"> 8.于审视上述说明的金额时，应注意未来生活成本很可能会因通胀而上升。</li>
        <li class="rank11 rank"> 9.请注意保费征费并不构成任何保费的一部分，并且不包含在相关的说明所示的计算中。</li>
      </ul>
		</div>
  </div>
</template>

<script>
import Header from '@/components/Header'

export default {
  components: {
    Header
  },
  data(){
    return{
      type:this.$route.params.type,
      name:this.$route.params.name
    }
  },
  created(){
    console.log(this.name,'name')
    console.log(this.type,'type')
  }
}
</script>

<style>

.doc_news{
  margin:3.5rem .5rem;
  background: #ffffff;
  padding-bottom:20px;
  color: #464646;
}
.rank2{
  padding-left:10px
}
.rank3{
  padding-left:20px
}
.rank4{
  padding-left:30px
}
.rank11{
  padding:5px 20px
}
.second{
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
}
.sub{
  font-weight: 500;
  font-size: 1.5rem;
}
.fixed-title{
  width: 100%;
  position: fixed !important;
  top:0;
}
</style>